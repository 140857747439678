import { Body, Color, Component, PhysicalObject, Properties, Vector, View } from 'outpost';
import { BACKGROUND_COLOR, PLAYER_DIAMETER, SPAWNING_ALPHA } from './constants.ts';
import { cloneDeep } from 'lodash';

export type PlayerData = Omit<Properties<Player>, 'id'>;

export class Player implements PhysicalObject, Component {
    id: string;
    name: string;
    score: number = 0;
    spawnInMs: number = 0;
    rank: number = 0;
    body: Body;
    color: Color;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
        this.body = Body.circle([PLAYER_DIAMETER, PLAYER_DIAMETER]);
        this.color = Color.fromStringHash(this.id);

        for (let i = 1; i < 16 && !isColorOk(this.color); ++i) {
            this.color = Color.fromStringHash(this.id.slice(i));
        }
    }

    getData(): PlayerData {
        return {
            name: this.name,
            score: this.score,
            spawnInMs: this.spawnInMs,
            body: this.body,
            color: this.color,
            rank: this.rank,
        };
    }

    setData(data: PlayerData) {
        Object.assign(this, data);
    }

    getBody(): Body {
        return this.body;
    }

    isSpawned(): boolean {
        return this.spawnInMs === 0;
    }

    getJumpVector(): Vector {
        let currentSpeed = this.body.velocity.getLength();
        let newDirection = { x: 0, y: -1 };

        if (currentSpeed > 0.1) {
            newDirection = this.body.velocity
                .getOrthogonals()
                .find(v => v.y <= 0)!
                .normalize();
        }

        return Vector.from(newDirection);
    }

    render(view: View): void {
        let rect = this.body.getRect();

        view.paint({
            rect,
            borderRadius: '50%',
            color: this.color
        });

        view.paint('border', {
            color: 'black',
            strokeSize: 1.5,
        });

        if (!this.isSpawned()) {
            view.paint({
                alpha: SPAWNING_ALPHA,
            });

            view.paint('timer', {
                text: (Math.round(this.spawnInMs / 100) / 10).toString(),
                borderRadius: 0,
                textColor: 'black',
                textSize: '50%',
                alpha: 1
            });
        }

        let textRect = rect
            .fromTopOutwards(200, 23, -5);

        view.paint('name', {
            rect: textRect,
            text: `${this.name} - ${this.score}`,
            textColor: 'black',
            textSize: '100%',
            // zIndex: 1
        });

        if (this.id === view.getActiveClientId() && this.isSpawned()) {
            let r = rect.getHalfWidth();
            let v = this.getJumpVector().mult(r);

            view.paint('indicator', {
                x: rect.x + v.x / 2,
                y: rect.y + v.y / 2,
                width: r,
                height: 2,
                angle: Math.atan2(v.y, v.x),
                color: 'black',
                anchorX: null,
                anchorY: null,
            });
        }
    }
}

function isColorOk(color: Color) {
    return color.getDistanceTo(BACKGROUND_COLOR) > 0.1 && color.getLuminance() > 0.4;
}
globalThis.ALL_FUNCTIONS.push(Player);