import { Room, RoomApi, RoomClient, View } from 'outpost';
import { MainRoom } from './main-room.ts';
import { Player } from './player.ts';
import { ALL_AUDIO_URLS, BACKGROUND_COLOR, LOCAL_STORAGE_USERNAME_KEY, WORLD_RECT } from './constants.ts';

export class LoginRoom implements Room {
    mainRoomId: string = '';
    idToName: Map<string, string> = new Map();

    onCreated(api: RoomApi): void {
        this.mainRoomId = api.createRoom(MainRoom);
    }

    onMount(api: RoomApi): void {
        api.configureRenderer({
            audios: ALL_AUDIO_URLS
        });
    }

    async $loginInteraction(api: RoomApi, client: RoomClient) {
        if (this.idToName.has(client.id)) {
            return;
        }

        let username = api.nativePrompt('What is your name?', LOCAL_STORAGE_USERNAME_KEY);

        if (!username) {
            return;
        }

        await api.waitForServerResponse();

        this.idToName.set(client.id, username);
    }

    async $startInteraction(api: RoomApi, client: RoomClient) {
        await api.waitForButtonPress('MouseLeft');

        let username = this.idToName.get(client.id);

        if (!username) {
            return;
        }

        await api.waitForServerResponse();

        api.addClientToRoom(MainRoom, this.mainRoomId, new Player(client.id, username));
    }

    render(view: View): void {
        view.paint('background', {
            color: BACKGROUND_COLOR
        });

        view.paint('instructions', {
            text: [
                '|Click to start',
                '',
                '|@0.5{Press *v* to configure volume}'
            ].join('\n'),
            textSize: '10%'
        });
    }
}
globalThis.ALL_FUNCTIONS.push(LoginRoom);