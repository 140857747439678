import { Body, Component, PhysicalObject, RectLike, View } from 'outpost';
import { WALL_COLOR } from './constants.ts';

export class Wall implements PhysicalObject, Component {
    body: Body;

    constructor(rectLike: RectLike) {
        this.body = Body.rectangle(rectLike);
    }

    getBody(): Body {
        return this.body;
    }

    render(view: View): void {
        view.paint({
            rect: this.body.getRect(),
            color: WALL_COLOR
        })
    }
}
globalThis.ALL_FUNCTIONS.push(Wall);