import { Logger, Room, RoomApi, Vector, View, moveObject } from 'outpost';
import { Bonus } from './bonus.ts';
import { WORLD_WIDTH, WORLD_HEIGHT, WALLS, BONUS_DIAMETER, BONUS_SPEED_BOOST, GRAVITY_ACCELERATION_PER_SECOND, JUMP_STRENGTH, MAX_BONUS_COUNT, PLAYER_DIAMETER, SPAWN_DURATION_SECS, SPAWN_POSITIONS, SPAWN_VELOCITY, SPEED_BOOST_ON_KILL, TIME_BEFORE_NEW_BONUS_SECS, VELOCITY_SCALE_ON_BUMP, WORLD_RECT, BACKGROUND_COLOR, BOOST_SOUND_URL, BOOST_VOLUME_STEPS, MASTER_VOLUME_STEPS, BACKGROUND_MUSIC_URL, EXPLOSION_SOUND_URL, RESET_DELAY_SECS, DEFAULT_TARGET_SCORE, LOCAL_STORAGE_USERNAME_KEY } from './constants.ts';
import { Player, PlayerData } from './player.ts';
import { Wall } from './wall.ts';
import { ExplosionEffect } from './explosion-effect.ts';

export class MainRoom implements Room<Player> {
    width: number = WORLD_WIDTH;
    height: number = WORLD_HEIGHT;
    players: Player[] = [];
    bonuses: Bonus[] = [];
    walls: Wall[] = [];
    lastBonusSpawnTime: number = 0;
    currentVolumeIndex: number = -1;
    masterVolume: number = 0;
    boostVolume: number = 0;
    isPaused: boolean = false;
    timeBeforeUnpause: number = 0;
    playersData: Map<string, PlayerData> = new Map();
    isFinished: boolean = false;
    playersByRank: Player[] = [];
    targetScore: number = DEFAULT_TARGET_SCORE;

    onCreated(api: RoomApi): void {
        for (let rect of WALLS) {
            this.walls.push(new Wall(rect));
        }

        // for (let i = 0; i < 1000; ++i) {
        //     this.spawnBonus();
        // }
    }

    onMount(api: RoomApi): void {
        this.toggleVolume(api);
    }

    onClientAdded(api: RoomApi, player: Player): void {
        Logger.info(`Connected: ${player.name}`);
        this.players.push(player);
        this.resetPlayer(api, player);

        let storedData = this.playersData.get(player.name);

        if (storedData) {
            player.setData(storedData);
            this.playersData.delete(player.name);
        }
    }

    onClientRemoved(api: RoomApi, player: Player): void {
        Logger.info(`Disconnected: ${player.name}`);
        this.players.remove(player);
        this.playersData.set(player.name, player.getData());
    }

    getPlayer(playerId: string): Player {
        return this.players.find(player => player.id === playerId)!;
    }

    async $toggleVolumeInteraction(api: RoomApi, player: Player) {
        await api.waitForButtonPress('KeyV');

        this.toggleVolume(api);
    }

    toggleVolume(api: RoomApi) {
        let requestedVolumeIndex: number;

        if (this.currentVolumeIndex === -1) {
            requestedVolumeIndex = parseInt(window.localStorage.getItem('glob-volume') ?? '0');

            if (isNaN(requestedVolumeIndex)) {
                requestedVolumeIndex = 0;
            }
        } else {
            requestedVolumeIndex = (this.currentVolumeIndex + 1) % MASTER_VOLUME_STEPS.length;
        }

        window.localStorage.setItem('glob-volume', requestedVolumeIndex.toString());

        this.currentVolumeIndex = requestedVolumeIndex;
        this.masterVolume = MASTER_VOLUME_STEPS[requestedVolumeIndex];
        this.boostVolume = BOOST_VOLUME_STEPS[requestedVolumeIndex];
    }

    async $resetScores(api: RoomApi) {
        await api.waitForButtonPress('Shift_Alt_KeyR');

        let targetScoreStr = api.nativePrompt('Target score?');
        let targetScore = parseInt(targetScoreStr ?? '') || DEFAULT_TARGET_SCORE;

        await api.waitForServerResponse();

        api.render();
        
        this.targetScore = targetScore;
        this.isPaused = true;
        this.isFinished = false;
        this.timeBeforeUnpause = RESET_DELAY_SECS;

        for (let player of this.players) {
            player.score = 0;
        }
    }

    async $pauseInteraction(api: RoomApi) {
        return;
        await api.waitForButtonPress('Space');
        await api.waitForServerResponse();

        this.isPaused = !this.isPaused;
    }

    async $jumpInteraction(api: RoomApi, player: Player) {
        await api.waitForButtonPress('MouseLeft');

        if (!player.isSpawned()) {
            return;
        }

        await api.waitForServerResponse();

        let newLength = Math.max(player.body.velocity.getLength(), JUMP_STRENGTH);
        let jumpVector = player.getJumpVector().mult(newLength);

        player.body.velocity = jumpVector;
    }

    async $cheat(api: RoomApi, player: Player) {
        return;
        await api.waitForButtonPress('Alt_KeyC');
        await api.waitForServerResponse();

        player.score += 1;
    }

    async $logout(api: RoomApi) {
        await api.waitForButtonPress('Alt_KeyL');

        localStorage.removeItem(LOCAL_STORAGE_USERNAME_KEY);
        console.log('Username cleared from local storage.');
    }

    onUpdate(api: RoomApi) {
        let currentTime = api.getServerCurrentTime();
        let elapsedMs = api.getServerTickDuration();
        let elapsedSecs = elapsedMs / 1000;

        if (this.isPaused) {
            this.timeBeforeUnpause -= elapsedSecs;

            if (this.timeBeforeUnpause <= 0) {
                this.isPaused = false;
            } else {
                api.render();
                return;
            }
        }

        if (this.isFinished) {
            api.render();
            return;
        }

        let gravityY = GRAVITY_ACCELERATION_PER_SECOND * elapsedSecs;
        let spawnedPlayers = this.players.filter(player => player.isSpawned());
        let notSpawnedPlayers = this.players.filter(player => !player.isSpawned());

        if (this.players.length === 0) {
            this.isPaused = false;
            this.isFinished = false;
            this.playersData.clear();
            this.bonuses = [];
            this.lastBonusSpawnTime = currentTime;
        } else if (currentTime - this.lastBonusSpawnTime > TIME_BEFORE_NEW_BONUS_SECS * 1000) {
            if (this.bonuses.length < MAX_BONUS_COUNT) {
                this.spawnBonus(api);
            }
            this.lastBonusSpawnTime = currentTime;
        }

        for (let player of spawnedPlayers) {
            player.body.velocity = player.body.velocity.add([0, gravityY]);
        }

        // let allObjects = [...spawnedPlayers, ...this.bonuses, ...this.walls];
        let worldObjects = [...this.walls];

        for (let player of spawnedPlayers) {
            let collision = moveObject(player, { worldObjects });

            if (collision) {
                if (collision.target instanceof Wall) {
                    let n = collision.collisionNormal;

                    player.body.velocity = player.body.velocity.mult(VELOCITY_SCALE_ON_BUMP);

                    // TODO: improve that
                    if (Math.abs(n.x) < 0.1) {
                        player.body.velocity = player.body.velocity.mult([1, -1]);
                    } else {
                        player.body.velocity = player.body.velocity.mult([-1, 1]);
                    }

                    if (player.body.velocity.getLength() < 1 && n.y < -0.5) {
                        player.body.velocity = Vector.zero();
                    }
                } else if (collision.target instanceof Bonus) {

                } else if (collision.target instanceof Player) {

                }
            }

            for (let bonus of this.bonuses) {
                if (player.body.position.getDistanceTo(bonus.body.position) < PLAYER_DIAMETER / 2 + BONUS_DIAMETER / 2) {
                    player.body.velocity = player.body.velocity.addLength(BONUS_SPEED_BOOST);
                    this.bonuses.remove(bonus);
                    api.now().playAudio({
                        audioUrl: BOOST_SOUND_URL,
                        volume: this.boostVolume
                    });
                    Logger.info(`Boost: ${player.name}`);
                    break;
                }
            }

            for (let otherPlayer of spawnedPlayers) {
                if (player === otherPlayer) {
                    continue;
                }

                let distance = player.body.position.getDistanceTo(otherPlayer.body.position);
                let minDistance = PLAYER_DIAMETER;

                if (distance < minDistance) {
                    let speed1 = player.body.velocity.getLength();
                    let speed2 = otherPlayer.body.velocity.getLength();
                    let winner = speed1 > speed2 ? player : otherPlayer;
                    let loser = winner === player ? otherPlayer : player;

                    winner.score += 1;
                    winner.body.velocity = winner.body.velocity.addLength(SPEED_BOOST_ON_KILL);
                    api.render(new ExplosionEffect(player.body.position.x, player.body.position.y));
                    api.now().playAudio({
                        audioUrl: EXPLOSION_SOUND_URL,
                        volume: this.masterVolume,
                    });
                    Logger.info(`Kill: ${winner.name} kills ${loser.name} (new score: ${winner.score})`);
                    this.resetPlayer(api, loser);
                }
            }
        }

        for (let player of notSpawnedPlayers) {
            player.spawnInMs = Math.max(0, player.spawnInMs - elapsedMs);
        }

        if (this.players.some(player => player.score >= this.targetScore)) {
            this.triggerEndGame();
        }

        api.render();
    }

    private triggerEndGame() {
        this.isFinished = true;
        this.playersByRank = [];

        this.playersByRank = this.players.slice().sort((p1, p2) => p2.score - p1.score);

        let currentRank = 0;
        let currentScore = Infinity;

        for (let i = 0; i < this.playersByRank.length; ++i) {
            let player = this.playersByRank[i];

            if (player.score !== currentScore) {
                currentRank = i + 1;
            }

            currentScore = player.score;
            player.rank = currentRank;
        }
    }

    spawnBonus(api: RoomApi) {
        let w = WORLD_RECT.width - 200;
        let h = WORLD_RECT.height - 200;
        let x = WORLD_RECT.x + (api.getRandomNumber() * w) - (w / 2);
        let y = WORLD_RECT.y + (api.getRandomNumber() * h) - (h / 2);
        let bonus = new Bonus(x, y);

        this.bonuses.push(bonus);
    }

    resetPlayer(api: RoomApi, player: Player) {
        let position = SPAWN_POSITIONS[Math.floor(api.getRandomNumber() * SPAWN_POSITIONS.length)];
        let vx = SPAWN_VELOCITY * (position.x < WORLD_RECT.x ? 1 : -1);

        player.spawnInMs = SPAWN_DURATION_SECS * 1000;
        player.body.position = position;
        player.body.velocity = Vector.from([vx, 0]);
    }

    render(view: View): void {
        view.paint('background', {
            color: BACKGROUND_COLOR
        });

        if (!this.isFinished) {
            view.addChild(this.walls);
            view.addChild(this.bonuses);
            view.addChild(this.players);
        }

        if (this.isPaused) {
            this.renderPauseOverlay(view);
        }

        if (this.isFinished) {
            this.renderEndOverlay(view);
        }

        this.renderVolume(view);
        this.renderTargetScore(view);

        view.paint('music', {
            audio: BACKGROUND_MUSIC_URL,
            audioLoop: true,
            audioVolume: this.masterVolume
        });
    }

    private renderPauseOverlay(view: View) {
        let timeStr = (Math.round(this.timeBeforeUnpause * 10) / 10).toString().padEnd(3, '.0');

        view.paint('overlay-bg', {
            color: 'black',
            alpha: 0.2,
            layer: 'top'
        });

        view.paint('overlay-text', {
            text: `Game starting in ${timeStr} secs...`,
            textSize: 60,
            layer: 'top'
        });
    }

    private renderEndOverlay(view: View) {
        view.addChild({
            rect: view.getRect().fromTopInwards('*', 100).translate(0, 40),
            text: `Game ended! Scores:`,
            textSize: 48,
        });

        let layout = view.layout()
            .centerToBottom()
            .width('25%w')
            .childHeight(60)
            .innerMargin(10)

        for (let player of this.playersByRank) {
            layout = layout.addChild({
                text: `${player.rank} - ${player.name} (${player.score} points)`,
                textSize: 36,
                horizontalAlign: 'left'
            });
        }
    }

    private renderVolume(view: View) {
        let volumeRect = WORLD_RECT.from('bottom-right', 'bottom-right', 300, 30);
        let volumeCharacters = ['🔊', '🔉', '🔈', '🔇'];
        let character = volumeCharacters[this.currentVolumeIndex];

        view.addChild({
            rect: volumeRect,
            text: `(v) ${character}`,
            horizontalAlign: 'right',
            textSize: '75%',
            textColor: 'white',
        });
    }

    private renderTargetScore(view: View) {
        let rect = WORLD_RECT.from('top', 'top', '*', 30);

        view.addChild({
            rect,
            text: `Target score: ${this.targetScore}`,
            horizontalAlign: 'center',
            textSize: '75%',
            textColor: 'white',
        });
    }
}
globalThis.ALL_FUNCTIONS.push(MainRoom);