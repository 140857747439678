import { Color, KeyCode, Rect, RectLike } from 'outpost';

export const WORLD_WIDTH = 1600;
export const WORLD_HEIGHT = 900;
export const WORLD_RECT = Rect.fromSize(WORLD_WIDTH, WORLD_HEIGHT);
export const WALL_WIDTH = 30;
export const PLAYER_DIAMETER = 40;
export const MARGIN = 1000000;
export const WALLS: RectLike[] = [
    WORLD_RECT.fromTopInwards(WORLD_WIDTH, WALL_WIDTH),
    WORLD_RECT.fromBottomInwards(WORLD_WIDTH, WALL_WIDTH),
    WORLD_RECT.fromRightInwards(WALL_WIDTH, WORLD_HEIGHT),
    WORLD_RECT.fromLeftInwards(WALL_WIDTH, WORLD_HEIGHT),
    // WORLD_RECT.withSize(100, 100)
];
export const GRAVITY_ACCELERATION_PER_SECOND = 8;
export const JUMP_STRENGTH = 8;
export const VELOCITY_SCALE_ON_BUMP = 0.85;
export const SPAWN_DURATION_SECS = 2;
// export const SPAWN_DURATION_SECS = 0.01;
export const SPAWN_VELOCITY = 12;
export const SPAWN_POSITIONS = [
    WORLD_RECT.getLandmarkPoint('top-left').add([100, 100]),
    WORLD_RECT.getLandmarkPoint('top-right').add([-100, 100]),
];
export const BONUS_SPEED_BOOST = 12;
export const BONUS_DIAMETER = 40;
export const MAX_BONUS_COUNT = 10;
export const TIME_BEFORE_NEW_BONUS_SECS = 5;
export const SPEED_BOOST_ON_KILL = 3;

export const VALID_KEYS: KeyCode[] = ['Enter', 'Space'];
export const BACKGROUND_COLOR = Color.from('lightgreen');
export const WALL_COLOR = Color.from('#333333');
export const SPAWNING_ALPHA = 0.6;

export const BACKGROUND_MUSIC_URL = 'assets/music.mp3';
export const EXPLOSION_SOUND_URL = 'assets/explosion.mp3';
export const BOOST_SOUND_URL = 'assets/boost.mp3';
export const ALL_AUDIO_URLS = [ BACKGROUND_MUSIC_URL, EXPLOSION_SOUND_URL, BOOST_SOUND_URL ];

export const MASTER_VOLUME_STEPS = [0.5, 0.1, 0.015, 0];
export const BOOST_VOLUME_STEPS = [0.8, 0.25, 0.06, 0];

export const EXPLOSION_SPRITE_SHEET_URL = "assets/explosion_4x4.png";
export const EXPLOSION_SPRITE_SHEET_ROW_SIZE = 4;
export const EXPLOSION_SPRITE_SHEET_COLUMN_SIZE = 4;
export const EXPLOSION_DIAMETER = PLAYER_DIAMETER * 2;
export const EXPLOSION_DURATION_SECS = 0.5;

export const RESET_DELAY_SECS = 5;
export const DEFAULT_TARGET_SCORE = 30;
export const LOCAL_STORAGE_USERNAME_KEY = 'glob-username';