import { Body, Component, PhysicalObject, View } from 'outpost';
import { BONUS_DIAMETER } from './constants.ts';

export class Bonus implements PhysicalObject, Component {
    body: Body;

    constructor(x: number, y: number) {
        this.body = Body.circle([x, y, BONUS_DIAMETER, BONUS_DIAMETER]);
    }

    getBody(): Body {
        return this.body;
    }

    render(view: View): void {
        view.paint({
            rect: this.body.getRect(),
            color: 'gold',
            borderRadius: 5,
        });

        view.paint('text', {
            text: '🚀',
            textSize: '70%',
            offsetY: 2,
            borderRadius: 0
        });

        view.paint('border', {
            color: 'black',
            strokeSize: 2
        });
    }
}
globalThis.ALL_FUNCTIONS.push(Bonus);