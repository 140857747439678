import { Component, View } from 'outpost';
import { EXPLOSION_DIAMETER, EXPLOSION_DURATION_SECS, EXPLOSION_SOUND_URL, EXPLOSION_SPRITE_SHEET_COLUMN_SIZE, EXPLOSION_SPRITE_SHEET_ROW_SIZE, EXPLOSION_SPRITE_SHEET_URL } from './constants.ts';

export class ExplosionEffect implements Component {
    duration = EXPLOSION_DURATION_SECS * 1000;
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    render(view: View): void {
        view.paint({
            image: EXPLOSION_SPRITE_SHEET_URL,
            x: this.x,
            y: this.y,
            width: EXPLOSION_DIAMETER,
            height: EXPLOSION_DIAMETER,
            imageSprite: { start: 0, end: '100%', duration: this.duration }
        });
    }
}
globalThis.ALL_FUNCTIONS.push(ExplosionEffect);